var site = site || {};

site.countdownTimer = {
  init: function (context) {
    var $countDownContainer = $('.js-countdown-timer', context);
    var $countDownTimer = $('.js-countdown', $countDownContainer);
    var dateString = $countDownContainer.attr('data-end-date');
    var hideEntireBanner = parseInt($countDownContainer.attr('data-hide-banner'));
    var isPerlgem = $('body').hasClass('perlgem');
    var countDownDate;

    if (hideEntireBanner || isPerlgem) {
      return;
    }
    // parse the date
    if (dateString) {
      countDownDate = Date.parse(dateString);
    }
    if (countDownDate) {
      this.startTimer($countDownContainer, $countDownTimer, countDownDate);

      return;
    }
  },
  startTimer: function ($countDownContainer, $countDownTimer, countDownDate) {
    var time,
      distance,
      days,
      hours,
      minutes,
      seconds;
    var hideDays = parseInt($countDownContainer.attr('data-hide-days'));
    var hideSeconds = parseInt($countDownContainer.attr('data-hide-seconds'));
    var intervalHandler = setInterval(function () {
      distance = countDownDate - new Date().getTime();

      if (distance < 0) {
        clearInterval(intervalHandler);

        return;
      } else if ($countDownContainer.hasClass('hidden')) {
        $countDownContainer.removeClass('hidden');
        $(window).resize();
      }

      time = '';
      days = Math.floor(distance / (1000 * 60 * 60 * 24));
      if (days > 0 && !hideDays) {
        if (days < 10) {
          time += '0';
        }
        time += days + ':';
      }

      hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      if (days > 0 || hours > 0) {
        if (hours < 10) {
          time += '0';
        }
        time += hours + ':';
      }

      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      if (days > 0 || hours > 0 || minutes > 0) {
        if (minutes < 10) {
          time += '0';
        }
        time += !hideSeconds ? minutes + ':' : minutes;
      }

      seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if ((days > 0 || hours > 0 || minutes > 0 || seconds > 0) && !hideSeconds) {
        if (seconds < 10) {
          time += '0';
        }
        time += seconds;
      }

      $countDownTimer.text(time);
    }, 1000);
  }
};

(function ($) {
  Drupal.behaviors.countdownTimerV1 = {
    attach: function (context) {
      site.countdownTimer.init(context);
    }
  };
})(jQuery);
